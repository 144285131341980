<template>
  <v-app>
    <div>
      <v-btn
        outlined
        color="info"
        x-large
        class="p-3 h-auto"
        @click="goToProfile"
      >
        <i class="fas fa-long-arrow-alt-left mr-3 text-info"></i>
        Back to profile
      </v-btn>
    </div>

    <form class="form card p-7 mt-5" @submit.prevent="onSubmit">
      <h3 class="mb-4">Personal info</h3>
      <div class="row">
        <div class="col-md-6 mb-7">
          <v-text-field
            outlined
            dense
            hide-details
            v-model="formData.first_name"
            @blur="$v.formData.first_name.$touch()"
          >
            <template v-slot:label>
              First Name <span class="text-danger">*</span>
            </template></v-text-field
          >
          <VulidateError
            :validationField="$v.formData.first_name"
            :params="[
              'required',
              'alphaNumericWithSpecialChars',
              {
                maxLength: {
                  number: $v.formData.first_name.$params.maxLength.max,
                },
              },
            ]"
          />
        </div>
        <div class="col-md-6 mb-7">
          <v-text-field
            outlined
            dense
            hide-details
            v-model="formData.last_name"
            @blur="$v.formData.last_name.$touch()"
          >
            <template v-slot:label>
              Last Name <span class="text-danger">*</span>
            </template></v-text-field
          >
          <VulidateError
            :validationField="$v.formData.last_name"
            :params="[
              'required',
              'alphaNumericWithSpecialChars',
              {
                maxLength: {
                  number: $v.formData.last_name.$params.maxLength.max,
                },
              },
            ]"
          />
        </div>
        <div class="col-12 mb-7">
          <v-select
            :items="countryList"
            label="Country"
            item-text="name"
            item-value="name"
            outlined
            dense
            hide-details
            :disabled="isLoading.countries"
            :loading="isLoading.countries"
            v-model="formData.country"
            :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </div>
      </div>

      <hr />
      <h3 class="mb-4">Role Details</h3>
      <div class="row">
        <div class="col-12 mb-7">
          <v-select
            :items="roles"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            v-model="formData.role_id"
            :disabled="isLoading.roles"
            :loading="isLoading.roles"
            @blur="$v.formData.role_id.$touch()"
            :menu-props="{ bottom: true, offsetY: true }"
          >
            <template v-slot:label>
              Current Role <span class="text-danger">*</span>
            </template></v-select
          >
          <VulidateError
            :validationField="$v.formData.role_id"
            :params="['required']"
          />
        </div>
        <div class="col-12 mb-7" v-if="isFullStack || isFrontend">
          <v-select
            :items="frontEndLanguages"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            :disabled="isLoading.frontEndLanguages"
            :loading="isLoading.frontEndLanguages"
            v-model="formData.FE_lang"
            @blur="$v.formData.FE_lang.$touch()"
            :menu-props="{ bottom: true, offsetY: true }"
          >
            <template v-slot:label>
              F.E Language <span class="text-danger">*</span>
            </template></v-select
          >
          <VulidateError
            :validationField="$v.formData.FE_lang"
            :params="['required']"
          />
        </div>
        <div class="col-12 mb-7" v-if="isFullStack || isBackend">
          <v-select
            :items="backEndLanguages"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            :disabled="isLoading.backEndLanguages"
            :loading="isLoading.backEndLanguages"
            v-model="formData.BE_lang"
            @blur="$v.formData.BE_lang.$touch()"
            :menu-props="{ bottom: true, offsetY: true }"
          >
            <template v-slot:label>
              B.E Language <span class="text-danger">*</span>
            </template></v-select
          >
          <VulidateError
            :validationField="$v.formData.BE_lang"
            :params="['required']"
          />
        </div>
        <template v-if="isFullStack">
          <div class="col-md-6 mb-7">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="formData.second_experience_years"
              @blur="$v.formData.second_experience_years.$touch()"
              ><template v-slot:label>
                Years of experience For Frontend
                <span class="text-danger">*</span>
              </template></v-text-field
            >
            <VulidateError
              :validationField="$v.formData.second_experience_years"
              :params="[
                'required',
                'numeric',
                {
                  minValue: {
                    number:
                      $v.formData.second_experience_years.$params.minValue.min,
                  },
                },
                {
                  maxValue: {
                    number:
                      $v.formData.second_experience_years.$params.maxValue.max,
                  },
                },
              ]"
            />
          </div>
          <div class="col-md-6 mb-7" v-if="false">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="formData.second_experience_months"
              @blur="$v.formData.second_experience_months.$touch()"
              ><template v-slot:label>
                Months of experience For Frontend
                <span class="text-danger">*</span>
              </template></v-text-field
            >
            <VulidateError
              :validationField="$v.formData.second_experience_months"
              :params="[
                'required',
                'numeric',
                {
                  minValue: {
                    number:
                      $v.formData.second_experience_months.$params.minValue.min,
                  },
                },
                {
                  maxValue: {
                    number:
                      $v.formData.second_experience_months.$params.maxValue.max,
                  },
                },
              ]"
            />
          </div>
          <div class="col-md-6 mb-7">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="formData.experience_years"
              @blur="$v.formData.experience_years.$touch()"
              ><template v-slot:label>
                Years of experience For Backend
                <span class="text-danger">*</span>
              </template></v-text-field
            >
            <VulidateError
              :validationField="$v.formData.experience_years"
              :params="[
                'required',
                'numeric',
                {
                  minValue: {
                    number: $v.formData.experience_years.$params.minValue.min,
                  },
                },
                {
                  maxValue: {
                    number: $v.formData.experience_years.$params.maxValue.max,
                  },
                },
              ]"
            />
          </div>
          <div class="col-md-6 mb-7" v-if="false">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="formData.experience_months"
              @blur="$v.formData.experience_months.$touch()"
              ><template v-slot:label>
                months of experience For Backend
                <span class="text-danger">*</span>
              </template></v-text-field
            >
            <VulidateError
              :validationField="$v.formData.experience_months"
              :params="[
                'required',
                'numeric',
                {
                  minValue: {
                    number: $v.formData.experience_months.$params.minValue.min,
                  },
                },
                {
                  maxValue: {
                    number: $v.formData.experience_months.$params.maxValue.max,
                  },
                },
              ]"
            />
          </div>
        </template>
        <div v-else class="col-12">
          <div class="row">
            <div
              class="col-12 mb-7"
              :class="{ 'col-md-12': isBackend || isFrontend }"
            >
              <v-text-field
                outlined
                dense
                hide-details
                v-model="formData.experience_years"
                @blur="$v.formData.experience_years.$touch()"
              >
                <template v-slot:label>
                  Years of experience <span class="text-danger">*</span>
                </template></v-text-field
              >
              <VulidateError
                :validationField="$v.formData.experience_years"
                :params="[
                  'required',
                  'numeric',
                  {
                    minValue: {
                      number: $v.formData.experience_years.$params.minValue.min,
                    },
                  },
                  {
                    maxValue: {
                      number: $v.formData.experience_years.$params.maxValue.max,
                    },
                  },
                ]"
              />
            </div>
            <div
              v-if="false"
              class="col-12 mb-7"
              :class="{ 'col-md-12': isBackend || isFrontend }"
            >
              <v-text-field
                outlined
                dense
                hide-details
                v-model="formData.experience_months"
                @blur="$v.formData.experience_months.$touch()"
              >
                <template v-slot:label>
                  Months of experience <span class="text-danger">*</span>
                </template></v-text-field
              >
              <VulidateError
                :validationField="$v.formData.experience_months"
                :params="[
                  'required',
                  'numeric',
                  {
                    minValue: {
                      number:
                        $v.formData.experience_months.$params.minValue.min,
                    },
                  },
                  {
                    maxValue: {
                      number:
                        $v.formData.experience_months.$params.maxValue.max,
                    },
                  },
                ]"
              />
            </div>
          </div>
        </div>
      </div>

      <hr />
      <h3 class="mb-4">Business Accounts</h3>
      <div class="mb-7 account" v-for="account in accounts" :key="account.key">
        <label class="font-weight-bold mb-2">{{ account.label }}</label>
        <div class="d-flex">
          <div
            class="account__icon bg-light py-3 px-3 d-flex justify-content-center"
          >
            <img
              :src="require(`@/assets/images/icons/${account.image}.svg`)"
              alt="account icon"
              width="20"
            />
          </div>
          <v-text-field
            outlined
            dense
            hide-details
            v-model="formData[account.key]"
            @blur="$v.formData[account.key].$touch()"
          >
          </v-text-field>
        </div>
        <VulidateError
          :validationField="$v.formData[account.key]"
          :params="['urlFormat']"
        />
      </div>

      <div
        class="mb-7 account"
        v-for="(account, index) in formData.websites"
        :key="index"
      >
        <label class="font-weight-bold mb-2">Website Link</label>
        <div class="d-flex">
          <div
            class="account__icon bg-light py-3 px-3 d-flex justify-content-center"
          >
            <img
              :src="require(`@/assets/images/icons/website.svg`)"
              alt="account icon"
              width="20"
            />
          </div>
          <v-text-field
            outlined
            dense
            hide-details
            v-model="formData.websites[index]"
            @blur="$v.formData.websites.$each.$iter[index].$touch()"
          >
          </v-text-field>
        </div>
        <VulidateError
          :validationField="$v.formData.websites.$each.$iter[index]"
          :params="['urlFormat']"
        />
      </div>

      <div class="text-right">
        <button
          class="btn btn-secondary font-weight-bolder font-size-h6 px-8"
          @click="goToProfile"
          type="button"
        >
          Cancel
        </button>
        <button
          class="btn btn-info font-weight-bolder font-size-h6 px-8 ml-5"
          :disabled="loading || $v.formData.$invalid"
        >
          Save
        </button>
      </div>
    </form>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
  requiredIf,
  numeric,
  maxValue,
  minValue,
} from "vuelidate/lib/validators";
import { urlFormat, alphaNumericWithSpecialChars } from "@/helpers";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ACCOUNTS_TYPES from "@/constants/accounts-types";
import VulidateError from "@/components/shared/vulidateError.vue";
export default {
  name: "EditTalentProfile",
  mixins: [validationMixin],
  components: { VulidateError },
  data() {
    return {
      ACCOUNTS_TYPES,
      profileId: null,
      isLoading: {
        roles: false,
        countries: false,
        frontEndLanguages: false,
        backEndLanguages: false,
      },
      formData: {
        country: null,
        first_name: null,
        last_name: null,
        role_id: null,
        BE_lang: null,
        FE_lang: null,
        experience_years: null,
        second_experience_years: null,
        experience_months: null,
        second_experience_months: null,
        linkedin: null,
        github: null,
        behance: null,
        dribbble: null,
        websites: [],
      },
      accounts: [
        {
          label: "Linkedin Link",
          key: "linkedin",
          image: "linkedin",
        },
        {
          label: "GitHub Link",
          key: "github",
          image: "github",
        },
        {
          label: "Behance Link",
          key: "behance",
          image: "behance",
        },
        {
          label: "Dribbble Link",
          key: "dribbble",
          image: "dribbble",
        },
      ],
      firstLoading: true,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Edit Profile" },
    ]);
  },
  created() {
    this.initData();
  },
  computed: {
    ...mapGetters({
      loading: "getLoadingStatus",
      roles: "talents/getRoles",
      countryList: "shared/getCountryList",
      frontEndLanguages: "talentProfile/getFrontEndLanguages",
      backEndLanguages: "talentProfile/getBackEndLanguages",
    }),
    isBackend() {
      return this.formData.role_id == 1;
    },
    isFrontend() {
      return this.formData.role_id == 2;
    },
    isFullStack() {
      return this.formData.role_id == 3;
    },
    selectedRole() {
      return this.formData.role_id;
    },
  },
  validations: {
    formData: {
      first_name: {
        required,
        maxLength: maxLength(100),
        alphaNumericWithSpecialChars,
      },
      last_name: {
        required,
        maxLength: maxLength(100),
        alphaNumericWithSpecialChars,
      },
      role_id: {
        required,
      },
      BE_lang: {
        required: requiredIf(function () {
          return this.isBackend || this.isFullStack;
        }),
      },
      FE_lang: {
        required: requiredIf(function () {
          return this.isFrontend || this.isFullStack;
        }),
      },
      experience_years: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(50),
        numeric,
      },
      second_experience_years: {
        required: requiredIf(function () {
          return this.isFullStack;
        }),
        minValue: minValue(0),
        maxValue: maxValue(50),
        numeric,
      },
      experience_months: {
        minValue: minValue(0),
        maxValue: maxValue(11),
        numeric,
      },
      second_experience_months: {
        minValue: minValue(0),
        maxValue: maxValue(11),
        numeric,
      },
      linkedin: { urlFormat },
      github: { urlFormat },
      behance: { urlFormat },
      dribbble: { urlFormat },
      websites: {
        $each: { urlFormat },
      },
    },
  },
  methods: {
    ...mapActions({
      getBasicInfo: "talentProfile/getBasicInfo",
      getFrontEndLanguages: "talentProfile/getFrontEndLanguages",
      getBackEndLanguages: "talentProfile/getBackEndLanguages",
      EditBasicInfo: "talentProfile/EditBasicInfo",
      getRoles: "talents/getRoles",
      getCountryList: "shared/getCountryList",
    }),
    goToProfile() {
      this.$router.push({
        name: "talent-profile",
        params: { id: `${this.profileId}` },
      });
    },
    initData() {
      this.isLoading.roles = true;
      this.isLoading.countries = true;
      this.getRoles().then(() => {
        this.isLoading.roles = false;
      });
      this.getCountryList().then(() => {
        this.isLoading.countries = false;
      });
      if (this.$route.params.id) {
        this.profileId = this.$route.params.id;
        this.getBasicInfo(this.profileId).then((res) => {
          this.formData.first_name = res.first_name;
          this.formData.last_name = res.last_name;
          this.formData.country = res.country;
          this.formData.role_id = res.role?.id || null;
          this.formData.BE_lang = res.BE_lang?.id || null;
          this.formData.FE_lang = res.FE_lang?.id || null;
          this.formData.experience_years = res.experience_years;
          this.formData.second_experience_years = res.second_experience_years;
          this.formData.experience_months = res.experience_months || 0;
          this.formData.second_experience_months =
            res.second_experience_months || 0;
          res.accounts.forEach((item) => {
            if (item.type !== 5) {
              this.formData[ACCOUNTS_TYPES[item.type]] = item.url;
            } else {
              this.formData.websites.push(item.url);
            }
          });
          if (this.formData.websites.length < 10) {
            for (let i = this.formData.websites.length; i < 10; i++) {
              this.formData.websites[i] = "";
            }
          }
          setTimeout(() => {
            this.$v.formData.$touch();
            this.firstLoading = false;
          });
        });
      }
    },
    onSubmit() {
      this.$v.formData.$touch();
      if (this.$v.formData.$anyError) {
        return;
      }
      let data = { ...this.formData };
      data.linkedIn = data.linkedin;
      data.gitHub = data.github;
      data.be_lang_id = data.BE_lang;
      data.fe_lang_id = data.FE_lang;
      delete data.linkedin;
      delete data.github;
      delete data.BE_lang;
      delete data.FE_lang;
      data.websites = data.websites.filter((website) => website);

      let payload = { id: this.profileId, data };
      this.EditBasicInfo(payload).then(() => {
        this.goToProfile();
      });
    },
  },
  watch: {
    selectedRole() {
      if (this.isFullStack) {
        this.isLoading.frontEndLanguages = true;
        this.isLoading.backEndLanguages = true;
        this.getFrontEndLanguages().then(
          () => (this.isLoading.frontEndLanguages = false)
        );
        this.getBackEndLanguages().then(
          () => (this.isLoading.backEndLanguages = false)
        );
      } else if (this.isFrontend) {
        this.isLoading.frontEndLanguages = true;
        this.getFrontEndLanguages().then(
          () => (this.isLoading.frontEndLanguages = false)
        );
      } else if (this.isBackend) {
        this.isLoading.backEndLanguages = true;
        this.getBackEndLanguages().then(
          () => (this.isLoading.backEndLanguages = false)
        );
      }
      // reset experience years & tech languages
      if (!this.firstLoading) {
        this.formData.BE_lang =
          this.formData.FE_lang =
          this.formData.experience_years =
          this.formData.second_experience_years =
            null;
        this.$v.formData.BE_lang.$reset();
        this.$v.formData.FE_lang.$reset();
        this.$v.formData.experience_years.$reset();
        this.$v.formData.second_experience_years.$reset();
        this.$v.formData.experience_months.$reset();
        this.$v.formData.second_experience_months.$reset();
      }
    },
  },
};
</script>

<style lang="scss">
.form {
  .account {
    &__icon {
      position: relative;
      z-index: 1;
    }
  }
}
</style>
