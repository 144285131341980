import { helpers } from "vuelidate/lib/validators";

export const urlFormat = (val) => {
  if (!val) {
    return true;
  } else {
    const enteredValue =
      val.startsWith("https://") || val.startsWith("http://")
        ? val
        : `https://${val}`;
    const expression =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    //eslint-disable-next-line
    // const expression = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \#.-])\/?$/
    var regexUrl = new RegExp(expression);
    const isMatch = enteredValue.match(regexUrl);
    return !!isMatch;
  }
};

export const lessThanOrEqualCurrentDate = (startMonth, startYear) => {
  let currentDate = new Date();
  let startDate = new Date();
  startDate.setMonth(startMonth - 1);
  startDate.setFullYear(startYear);

  return (
    startMonth && startYear && startDate.getTime() <= currentDate.getTime()
  );
};

export const validDate = (startMonth, startYear, endMonth, endYear) => {
  let startDate = new Date();
  let endDate = new Date();
  startDate.setMonth(startMonth - 1);
  startDate.setFullYear(startYear);
  endDate.setMonth(endMonth - 1);
  endDate.setFullYear(endYear);

  return (
    startMonth &&
    startYear &&
    endMonth &&
    endYear &&
    startDate.getTime() <= endDate.getTime()
  );
};

export const getUrl = (url) => {
  let newUrl = window.decodeURIComponent(url);
  newUrl = newUrl.trim().replace(/\s/g, "");

  if (/^(:\/\/)/.test(newUrl)) {
    return `http${newUrl}`;
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `http://${newUrl}`;
  }
  return newUrl;
};

export const convertTimstampToDateTime = (timestamp) => {
  if (timestamp) {
    let dateValue = new Date(timestamp);
    let date = new Date(timestamp).toLocaleDateString();
    let time = dateValue.toLocaleTimeString("en-US");
    return { date, time };
  } else {
    return { date: "-", time: "-" };
  }
};

export const replacePlainTextLinkToHtml = (text) => {
  return text.replace(
    // eslint-disable-next-line no-useless-escape
    /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/gim,
    '<a href="$1">$1</a>'
  );
};

export const alphaNumericWithSpecialChars = helpers.regex(
  "alphaNumericWithSpecialChars",
  /^[\x20-\x7E]*$/
);

export const truncate = (str, value) =>
  str.length > value
    ? str.substring(0, value) + "..."
    : str.substring(0, value);
